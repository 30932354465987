<template>
  <div
    class="sticky top-0 z-30 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden"
  >
    <button
      type="button"
      class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
      @click="onOpenSidebar"
    >
      <span class="sr-only">Open sidebar</span>
      <Bars3Icon class="h-6 w-6" aria-hidden="true" />
    </button>

    <nuxt-link v-if="appUserMe" to="/settings/profile" class="ml-auto">
      <span class="sr-only">Your profile</span>
      <img
        class="h-8 w-8 rounded-full bg-gray-50"
        :src="appUserMe.profileImage"
        :alt="appUserMe.displayName"
      />
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
import { Bars3Icon } from "@heroicons/vue/24/outline";
import { storeToRefs } from "pinia";

const { appUserMe } = storeToRefs(useAppUserStore());

const emit = defineEmits(["open-sidebar"]);

const onOpenSidebar = () => {
  emit("open-sidebar");
};
</script>
