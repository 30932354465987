<template>
  <!-- Open/close sidebar for mobile -->
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="relative z-30 lg:hidden" @close="onCloseSidebar">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div
                class="absolute left-full top-0 flex w-16 justify-center pt-5"
              >
                <button
                  type="button"
                  class="-m-2.5 p-2.5"
                  @click="onCloseSidebar"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <SkeletonText v-if="loading" class="mt-8" />
            <template v-else>
              <div
                class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2"
              >
                <div class="flex h-16 shrink-0 items-center">
                  <img
                    v-if="clients.length <= 0 || !activeClient"
                    class="h-8 w-auto"
                    src="~/assets/img/logo-wide.png"
                    alt="DatAds"
                  />
                  <SidebarClientSelect
                    v-else
                    :clients="clients"
                    :active-client="activeClient"
                  />
                </div>
                <SidebarNav
                  :navigation="navigation"
                  :num-unviewed-notifications="numUnviewedNotifications"
                  :folders="folders"
                  :can-manage="canManage"
                  :reports="reports"
                  :boards="boards"
                  :testing-log-reports="testingLogReports"
                  :launch-reports="launchReports"
                  :open-folders="openFolders"
                  :has-testing-log-report="hasTestingLogReport"
                  :has-launch-report="hasLaunchReport"
                  :has-inspiration-board="hasInspirationBoard"
                  @create:report="onCreateReport"
                  @delete:report="onDeleteReport"
                  @share:report="onShareReport"
                  @create:testinglog:report="onCreateTestingLogReport"
                  @delete:testinglog:report="onDeleteTestingLogReport"
                  @share:testinglog:report="onShareTestingLogReport"
                  @create:launch:report="onCreateLaunchReport"
                  @delete:launch:report="onDeleteLaunchReport"
                  @share:launch:report="onShareLaunchReport"
                  @create:board="onCreateBoard"
                  @delete:board="onDeleteBoard"
                  @share:board="onShareBoard"
                  @toggle:folder="onToggleFolder"
                  @create:folder="onCreateFolder"
                  @delete:folder="onDeleteFolder"
                  @rename:folder="onRenameFolder"
                  @duplicate:folder="onDuplicateFolder"
                />
              </div>
            </template>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { PropType, FunctionalComponent, HTMLAttributes, VNodeProps } from "vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { Client } from "~/types/Client";
import {
  AnyCreativeReport,
  CreativeReportType,
} from "~/types/AnyCreativeReport";
import { Folder } from "~/types/FolderStructure";
import { InspirationBoard } from "~/types/InspirationBoard";
import { TestingLogReport } from "~/types/TestingLog";
import { LaunchReport } from "~/types/LaunchReport";

defineProps({
  loading: {
    type: Boolean,
    required: true,
    default: false,
  },
  navigation: {
    type: Array as PropType<
      {
        name: string;
        href: string;
        icon: FunctionalComponent<HTMLAttributes & VNodeProps, {}, any>;
        current: boolean;
        isNotification: boolean;
      }[]
    >,
    required: true,
    default: () => [],
  },
  clients: {
    type: Array as PropType<Client[]>,
    required: true,
    default: () => [],
  },
  activeClient: {
    type: Object as PropType<Client | null>,
    required: false,
    default: null,
  },
  numUnviewedNotifications: {
    type: Number,
    required: true,
    default: 0,
  },
  folders: {
    type: Array as PropType<Folder[]>,
    required: true,
    default: () => [],
  },
  sidebarOpen: {
    type: Boolean,
    required: true,
    default: false,
  },
  canManage: {
    type: Boolean,
    required: true,
    default: false,
  },
  reports: {
    type: Array as PropType<AnyCreativeReport[]>,
    required: true,
    default: () => [],
  },
  boards: {
    type: Array as PropType<InspirationBoard[]>,
    required: true,
    default: () => [],
  },
  testingLogReports: {
    type: Array as PropType<TestingLogReport[]>,
    required: true,
    default: () => [],
  },
  openFolders: {
    type: Array as PropType<number[]>,
    required: true,
    default: () => [],
  },
  hasTestingLogReport: {
    type: Boolean,
    required: true,
  },
  launchReports: {
    type: Array as PropType<LaunchReport[]>,
    required: true,
    default: () => [],
  },
  hasLaunchReport: {
    type: Boolean,
    required: true,
  },
  hasInspirationBoard: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits([
  "close-sidebar",
  "create:report",
  "delete:report",
  "share:report",
  "create:board",
  "delete:board",
  "share:board",
  "create:testinglog:report",
  "delete:testinglog:report",
  "share:testinglog:report",
  "create:launch:report",
  "delete:launch:report",
  "share:launch:report",
  "toggle:folder",
  "create:folder",
  "delete:folder",
  "rename:folder",
  "duplicate:folder",
]);

const onToggleFolder = (folderId: number) => {
  emit("toggle:folder", folderId);
};

const onCreateFolder = () => {
  emit("create:folder");
};

const onDeleteFolder = (folderId: number) => {
  emit("delete:folder", folderId);
};

const onDeleteBoard = (file: { id: string; type: string }) => {
  emit("delete:board", file);
};

const onDeleteReport = (file: { id: string; type: string }) => {
  emit("delete:report", file);
};

const onDeleteTestingLogReport = (file: { id: string; type: string }) => {
  emit("delete:testinglog:report", file);
};

const onDeleteLaunchReport = (file: { id: string; type: string }) => {
  emit("delete:launch:report", file);
};

const onShareBoard = (file: { id: string; type: string }) => {
  emit("share:board", file);
};

const onShareReport = (file: { id: string; type: string }) => {
  emit("share:report", file);
};

const onShareTestingLogReport = (file: { id: string; type: string }) => {
  emit("share:testinglog:report", file);
};

const onShareLaunchReport = (file: { id: string; type: string }) => {
  emit("share:launch:report", file);
};

const onCreateReport = (type: CreativeReportType, folderId: number) => {
  emit("create:report", { type, folderId });
};

const onCreateBoard = (folderId: number) => {
  emit("create:board", folderId);
};

const onCreateTestingLogReport = (folderId: number) => {
  emit("create:testinglog:report", folderId);
};

const onCreateLaunchReport = (folderId: number) => {
  emit("create:launch:report", folderId);
};

const onRenameFolder = (folderId: number) => {
  emit("rename:folder", folderId);
};

const onDuplicateFolder = (folderId: number) => {
  emit("duplicate:folder", folderId);
};

const onCloseSidebar = () => {
  emit("close-sidebar");
};
</script>
