<template>
  <li v-if="appUserMe" class="-mx-6 mt-auto">
    <nuxt-link
      to="/settings/profile"
      class="flex items-center gap-x-4 px-6 py-3 text-sm font-normal leading-6 text-gray-900 hover:bg-gray-50"
    >
      <img
        class="h-8 w-8 rounded-full bg-gray-50"
        :src="appUserMe.profileImage"
        :alt="appUserMe.displayName"
      />
      <div class="flex flex-col">
        <span class="sr-only">Your profile</span>
        <span aria-hidden="true">{{ appUserMe.displayName }}</span>
        <span aria-hidden="true" class="text-gray-400 font-normal"
          >{{ appUserMe.displayRole
          }}<span
            v-if="appUserMe.position"
            aria-hidden="true"
            class="text-gray-400 leading-4 font-normal"
            >, {{ appUserMe.position }}</span
          ></span
        >
      </div>
      <ChevronDoubleRightIcon
        v-tooltip="'To your profile'"
        class="h-5 w-5 ml-auto text-gray-400"
      />
    </nuxt-link>
  </li>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ChevronDoubleRightIcon } from "@heroicons/vue/24/outline";

const { appUserMe } = storeToRefs(useAppUserStore());
</script>
