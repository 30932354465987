<template>
  <div class="lg:pl-72">
    <div
      v-if="showNavbar"
      class="sticky top-0 z-30 flex shrink-0 items-center gap-x-4 bg-yellow-50 px-4 sm:gap-x-6 sm:px-6 lg:px-8 py-2"
    >
      <div class="flex items-center flex-1 gap-x-4 self-stretch">
        <div class="flex-shrink-0">
          <ExclamationTriangleIcon
            class="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <p class="text-xs text-yellow-700">
          <span class="font-semibold mr-1">{{ message.title }}</span
          ><span class="mr-1">{{ message.message }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";

const { hasFeature, getValue } = useFeatureFlags();
const showNavbar = computed(() => hasFeature("warning-header"));
const messageStr = computed(() => getValue<string>("warning-header"));
const message = computed<{ title: string; message: string }>(() => {
  try {
    return JSON.parse(messageStr.value);
  } catch (e) {
    return { title: "", message: "" };
  }
});
</script>
