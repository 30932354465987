<template>
  <div class="">
    <SidebarMobile
      :navigation="navigation"
      :sidebar-open="sidebarOpen"
      :clients="clients"
      :active-client="activeClient"
      :num-unviewed-notifications="numUnviewedNotifications"
      :folders="folders"
      :loading="isLoadingSidebar"
      :can-manage="canManage"
      :boards="inspirationBoards"
      :testing-log-reports="testingLogReports"
      :launch-reports="launchReports"
      :reports="reports"
      :open-folders="openFolders"
      :has-testing-log-report="hasTestingLogReport"
      :has-launch-report="hasLaunchReport"
      :has-inspiration-board="hasInspirationBoard"
      @close-sidebar="sidebarOpen = false"
      @create:report="onCreateReport"
      @delete:report="onDeleteReport"
      @share:report="onShareReport"
      @create:testinglog:report="onCreateTestingLogReport"
      @delete:testinglog:report="onDeleteTestingLogReport"
      @share:testinglog:report="onShareTestingLogReport"
      @create:launch:report="onCreateLaunchReport"
      @delete:launch:report="onDeleteLaunchReport"
      @share:launch:report="onShareLaunchReport"
      @create:board="onCreateBoard"
      @delete:board="onDeleteBoard"
      @share:board="onShareBoard"
      @toggle:folder="toggleFolder"
      @create:folder="createFolder"
      @delete:folder="onDeleteFolder"
      @rename:folder="onRenameFolder"
      @duplicate:folder="onDuplicateFolder"
    />
    <SidebarDesktop
      :navigation="navigation"
      :clients="clients"
      :active-client="activeClient"
      :num-unviewed-notifications="numUnviewedNotifications"
      :folders="folders"
      :loading="isLoadingSidebar"
      :can-manage="canManage"
      :boards="inspirationBoards"
      :testing-log-reports="testingLogReports"
      :launch-reports="launchReports"
      :reports="reports"
      :open-folders="openFolders"
      :has-testing-log-report="hasTestingLogReport"
      :has-launch-report="hasLaunchReport"
      :has-inspiration-board="hasInspirationBoard"
      @create:report="onCreateReport"
      @delete:report="onDeleteReport"
      @share:report="onShareReport"
      @create:testinglog:report="onCreateTestingLogReport"
      @delete:testinglog:report="onDeleteTestingLogReport"
      @share:testinglog:report="onShareTestingLogReport"
      @create:launch:report="onCreateLaunchReport"
      @delete:launch:report="onDeleteLaunchReport"
      @share:launch:report="onShareLaunchReport"
      @create:board="onCreateBoard"
      @delete:board="onDeleteBoard"
      @share:board="onShareBoard"
      @toggle:folder="toggleFolder"
      @create:folder="createFolder"
      @delete:folder="onDeleteFolder"
      @rename:folder="onRenameFolder"
      @duplicate:folder="onDuplicateFolder"
    />
    <NavbarMobile @open-sidebar="sidebarOpen = true" />
    <NavbarTrialExpiresSoon />
    <NavbarLoadingData />
    <NavbarConnection />
    <NavbarRemoteWarning />
    <main :class="['lg:pl-72', isLoadingPage ? 'opacity-0' : '']">
      <slot />
    </main>
    <NotificationWrapper />
    <FolderRenameModal
      v-if="folderId"
      :open="openRenameFolderModal"
      :folder-id="folderId"
    />
    <FolderDuplicateModal
      v-if="folderId"
      :open="openDuplicateFolderModal"
      :folder-id="folderId"
      @duplicate:success="onDuplicateFolderSuccess"
    />
    <UsageExceededModal :open="openUsageExceededModal" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import {
  SquaresPlusIcon,
  // ClockIcon,
  Cog8ToothIcon,
  PhotoIcon,
  RocketLaunchIcon,
  HomeIcon,
} from "@heroicons/vue/24/outline";
import { storeToRefs } from "pinia";
import {
  AnyCreativeReport,
  CreativeReportType,
} from "~/types/AnyCreativeReport";
import { PaymentPlan, paymentPlantoPaymentPlanIF } from "~/types/Payment";

useHead({
  htmlAttrs: {
    class: "h-full bg-gray-50",
  },
  bodyAttrs: {
    class: "h-full",
  },
});

defineProps({
  isLoadingPage: {
    type: Boolean,
    default: false,
  },
});

const sidebarOpen = ref(false);
const isLoadingSidebar = ref(true);
const isMounting = ref(true);

const appUserHelpers = useAppUser();
const appUserStore = useAppUserStore();
const { appUserMe } = storeToRefs(appUserStore);
const isAdmin = computed(() => appUserHelpers.isAdmin(appUserMe.value));
const isMember = computed(() => appUserHelpers.isMember(appUserMe.value));
const canManage = computed(() => isAdmin.value || isMember.value);

const { hasFeature } = useFeatureFlags();
const hasTestingLogReport = hasFeature("testing-log-as-report_2024-03-27");
const hasLaunchReport = hasFeature("launch-report_2024-08-20");
const hasInspirationBoard = hasFeature("inspiration-board_2024-01-01");
const hasLaunchCockpitFeature = hasFeature("launch-cockpit_2024-07-15");

const hasLaunchCockpitReport = computed(() => {
  if (!hasLaunchCockpitFeature || activeClient.value == null) {
    return false;
  }
  const launchCockpitReportIdMaybe = activeClient.value.launchCockpitReport;
  if (launchCockpitReportIdMaybe == null) {
    return false;
  }
  const loadedReport = getLaunchReportsOfClient
    .value(activeClient.value.id)
    .find((report) => report.id === launchCockpitReportIdMaybe);
  return loadedReport != null;
});

const openFolders = ref<number[]>([]);

const folderStructureStore = useFolderStructureStore();
const folders = computed(() => {
  if (!activeClient.value) {
    return [];
  }
  return folderStructureStore.getFoldersOfClient(activeClient.value.id);
});

const route = useRoute();
const router = useRouter();

const mode = computed(() => {
  const modeMaybe = route.query.mode as string | undefined | null;
  return modeMaybe == null ? null : modeMaybe;
});

const openRenameFolderModal = computed(() => mode.value === "rename-folder");
const openDuplicateFolderModal = computed(
  () => mode.value === "duplicate-folder",
);
const openUsageExceededModal = computed(() => mode.value === "usage-exceeded");

const folderId = computed(() => parseInt(route.query.folderId as string));

const creativeReportingStore = useCreativeReportingStore();
const { getReportsOfClient: getCreativeReportingReportsOfClient } = storeToRefs(
  creativeReportingStore,
);

const creativeComparisonStore = useCreativeComparisonStore();
const { getReportsOfClient: getCreativeComparisonReportsOfClient } =
  storeToRefs(creativeComparisonStore);

const inspirationBoardStore = useInspirationBoardStore();
const { getBoardsOfClient: getInspirationBoardsOfClient } = storeToRefs(
  inspirationBoardStore,
);

const testingLogStore = useTestingLogStore();
const { getReportsOfClient: getTestingLogReportsOfClient } =
  storeToRefs(testingLogStore);

const launchReportStore = useLaunchReportStore();
const { getReportsOfClient: getLaunchReportsOfClient } =
  storeToRefs(launchReportStore);

const clientStore = useClientStore();
const { clients, activeClient } = storeToRefs(clientStore);

const notificationStore = useNotificationStore();
const { numUnviewedNotifications } = storeToRefs(notificationStore);

const {
  deleteReport,
  getReportId,
  createReport,
  getReportByKey,
  copyShareLink,
} = useAnyCreativeReport();

const { createBoard, copyBoardShareLink, deleteBoard } = useInspirationBoard();

const {
  createReport: createTestingLogReport,
  deleteReport: deleteTestingLogReport,
  copyShareLink: copyTestingLogShareLink,
} = useTestingLog();

const {
  createReport: createLaunchReport,
  deleteReport: deleteLaunchReport,
  copyShareLink: copyLaunchReportShareLink,
} = useLaunchReport();

watch(
  () => activeClient.value,
  async (client) => {
    if (client && !isMounting.value) {
      await listClientDependentResources(client.id);
      await showUsageExceededModalMaybe();
    }
  },
);

const reports = computed<AnyCreativeReport[]>(() => {
  if (!activeClient.value) {
    return [];
  }

  const anyCreativeReportingReport: AnyCreativeReport[] =
    getCreativeReportingReportsOfClient
      .value(activeClient.value.id)
      .map((report) => ({
        ...report,
        type: "topcreatives",
      }));

  const anyCreativeComparisonReport: AnyCreativeReport[] =
    getCreativeComparisonReportsOfClient
      .value(activeClient.value.id)
      .map((report) => ({
        ...report,
        type: "comparison",
      }));

  return [...anyCreativeReportingReport, ...anyCreativeComparisonReport];
});

const inspirationBoards = computed(() => {
  if (!activeClient.value) {
    return [];
  }

  return getInspirationBoardsOfClient.value(activeClient.value.id);
});

const testingLogReports = computed(() => {
  if (!activeClient.value) {
    return [];
  }

  return getTestingLogReportsOfClient.value(activeClient.value.id);
});

const launchReports = computed(() => {
  if (!activeClient.value || !hasLaunchCockpitReport.value) {
    return [];
  }

  return getLaunchReportsOfClient
    .value(activeClient.value.id)
    .filter((report) => report.id !== activeClient.value?.launchCockpitReport);
});

const navigation = computed(() =>
  [
    {
      name: "Creative Cockpit",
      href: "/creative-cockpit",
      icon: HomeIcon,
      current: useIsCurrentRoute("/creative-cockpit"),
      isNotification: false,
    },
    {
      name: "Launch Cockpit",
      href: "/launch/cockpit",
      icon: RocketLaunchIcon,
      current: useIsCurrentRoute("/launch/cockpit"),
      isNotification: false,
    },
    {
      name: "Creative Library",
      href: "/creative-library",
      icon: PhotoIcon,
      current: useIsCurrentRoute("/creative-library"),
      isNotification: false,
    },
    {
      name: "Settings & Members",
      href: "/settings/team",
      icon: Cog8ToothIcon,
      current: useIsCurrentRoute("/settings", {
        noMatchPaths: ["/settings/connections"],
      }),
      isNotification: false,
    },
    {
      name: "Add Connection",
      href: "/settings/connections",
      icon: SquaresPlusIcon,
      current: useIsCurrentRoute("/settings/connections", { matchExact: true }),
      isNotification: false,
    },
  ].filter(
    (item) => item.href !== "/launch/cockpit" || hasLaunchCockpitReport.value,
  ),
);

const { getActiveWorkspace } = useWorkspace();
const activeWorkspace = computed(() => getActiveWorkspace(appUserMe.value));
const paymentPlanInfo = computed(() =>
  activeWorkspace.value != null
    ? paymentPlantoPaymentPlanIF[activeWorkspace.value.plan]
    : null,
);
const currentMonthUsage = computed(() => {
  return activeWorkspace.value?.curMonthUsage ?? 0;
});
const maxAllowedUsage = computed(() =>
  paymentPlanInfo.value != null
    ? paymentPlanInfo.value.restrictions.analyzedAds
    : 0,
);
const isUsageLimitExceeded = computed(
  () =>
    currentMonthUsage.value > maxAllowedUsage.value &&
    maxAllowedUsage.value > 0,
);
const isCustomPlan = computed(
  () => paymentPlanInfo.value?.id === PaymentPlan.CUSTOM,
);

onMounted(async () => {
  isMounting.value = true;
  isLoadingSidebar.value = true;
  await nextTick();
  // Need to be loaded before clients in order to get default provider of client
  await adAccountStore.listAdAccounts();
  await clientStore.listClients();
  await appUserStore.listAppUsers();
  isLoadingSidebar.value = false;
  if (activeClient.value) {
    await listClientDependentResources(activeClient.value.id);
  }
  await showUsageExceededModalMaybe();
  listBackgroundResources();
  isMounting.value = false;
});

const listClientDependentResources = async (clientId: number) => {
  await listReportsAndBoardsOfClient(clientId);
  await listFoldersOfClient(clientId);
};

const listReportsAndBoardsOfClient = async (clientId: number) => {
  await creativeReportingStore.listReports(clientId);
  await creativeComparisonStore.listReports(clientId);
  if (hasInspirationBoard) {
    await inspirationBoardStore.listBoards(clientId);
  }
  if (hasTestingLogReport) {
    await testingLogStore.listReports(clientId);
  }
  if (hasLaunchCockpitFeature) {
    await launchReportStore.listReports(clientId);
  }
};

const listFoldersOfClient = async (clientId: number) => {
  await folderStructureStore.listFoldersOfClient(clientId);
  openFolders.value = folderStructureStore
    .getFoldersOfClient(clientId)
    .map((folder) => folder.id);
};

const toggleFolder = (folderId: number) => {
  if (openFolders.value.includes(folderId)) {
    openFolders.value = openFolders.value.filter((id) => id !== folderId);
  } else {
    openFolders.value = [...openFolders.value, folderId];
  }
};

const adAccountStore = useAdAccountStore();
const gaAccountStore = useGaAccountStore();
const connectionStore = useConnectionStore();

const listBackgroundResources = async () => {
  await connectionStore.listConnections();
  await gaAccountStore.listGaAccounts();
};

const { notify } = useNotifications();

const onDeleteReport = async (report: {
  id: string;
  type: CreativeReportType;
}) => {
  if (!canManage.value) {
    return;
  }

  const reportId = getReportId(report.id);
  await deleteReport({ id: reportId, type: report.type });
};

const onCreateReport = async (dto: {
  type: CreativeReportType;
  folderId: number | null;
}) => {
  if (activeClient.value == null || !canManage.value) {
    return;
  }

  await createReport({
    type: dto.type,
    clientId: activeClient.value.id,
    folderId: dto.folderId,
  });
};

const onShareReport = (report: { id: string; type: CreativeReportType }) => {
  const reportMaybe = getReportByKey(report.id);
  if (reportMaybe) copyShareLink({ uuid: reportMaybe.uuid, type: report.type });
};

const onDeleteBoard = async (board: { id: string }) => {
  if (!canManage.value) {
    return;
  }

  const boardMaybe = inspirationBoardStore.getBoardByUuid(board.id);
  if (boardMaybe) {
    await deleteBoard(boardMaybe.id);
  }
};

const onCreateBoard = async (folderId: number | null = null) => {
  if (activeClient.value == null || !canManage.value) {
    return;
  }

  await createBoard({ clientId: activeClient.value.id, folderId });
};

const onShareBoard = (board: { id: string }) => {
  copyBoardShareLink(board.id);
};

const onDeleteTestingLogReport = async (report: { id: string }) => {
  if (!canManage.value) {
    return;
  }

  const reportMaybe = testingLogStore.getReportByUuid(report.id);
  if (reportMaybe) {
    await deleteTestingLogReport(reportMaybe.id);
  }
};

const onCreateTestingLogReport = async (folderId: number | null = null) => {
  if (activeClient.value == null || !canManage.value) {
    return;
  }

  await createTestingLogReport({
    clientId: activeClient.value.id,
    folderId,
  });
};

const onShareTestingLogReport = (report: { id: string }) => {
  copyTestingLogShareLink(report.id);
};

const onDeleteLaunchReport = async (report: { id: string }) => {
  if (!canManage.value) {
    return;
  }

  const reportMaybe = launchReportStore.getReportByUuid(report.id);
  if (reportMaybe) {
    await deleteLaunchReport(reportMaybe.id);
  }
};

const onCreateLaunchReport = async (folderId: number | null = null) => {
  if (activeClient.value == null || !canManage.value) {
    return;
  }

  await createLaunchReport({
    clientId: activeClient.value.id,
    folderId,
  });
};

const onShareLaunchReport = (report: { id: string }) => {
  copyLaunchReportShareLink(report.id);
};

const createFolder = async () => {
  if (activeClient.value == null || !canManage.value) {
    return;
  }

  const folderIdMaybe = await folderStructureStore.createFolder({
    clientId: activeClient.value.id,
    title: null,
  });

  if (folderIdMaybe != null && typeof folderIdMaybe !== "string") {
    notify("", "Folder created successfully");
    openFolders.value.push(folderIdMaybe.id);
  }
};

const onDeleteFolder = async (folderId: number) => {
  if (activeClient.value == null || !canManage.value) {
    return;
  }

  const confirmed = confirm(
    "Are you sure you want to delete this folder? This action cannot be undone.",
  );

  if (!confirmed) {
    return;
  }

  await folderStructureStore.deleteFolder({ folderId });
};

const onRenameFolder = async (folderId: number) => {
  if (!canManage.value) {
    return;
  }
  await router.push({
    path: route.path,
    query: {
      ...route.query,
      mode: "rename-folder",
      folderId,
    },
  });
};

const onDuplicateFolder = async (folderId: number) => {
  if (!canManage.value) {
    return;
  }
  await router.push({
    path: route.path,
    query: {
      ...route.query,
      mode: "duplicate-folder",
      folderId,
    },
  });
};

const onDuplicateFolderSuccess = async (folderId: number) => {
  openFolders.value.push(folderId);
  if (activeClient.value == null) {
    return;
  }
  await listReportsAndBoardsOfClient(activeClient.value.id);
};

const showUsageExceededModalMaybe = async () => {
  const allowedPages = [
    "/auth",
    "/connect",
    "/onboarding",
    "/payment",
    "/settings",
    "/signup",
  ];
  const isOnAllowedPage = allowedPages.some((page) => useIsCurrentRoute(page));
  const show =
    !isCustomPlan.value && isUsageLimitExceeded.value && !isOnAllowedPage;
  if (show) {
    await router.push({
      path: route.path,
      query: {
        ...route.query,
        mode: "usage-exceeded",
      },
    });
  }
};
</script>
