<template>
  <div class="lg:pl-72">
    <div
      v-if="showNavbar"
      class="sticky top-0 z-30 flex shrink-0 items-center gap-x-4 bg-yellow-50 px-4 sm:gap-x-6 sm:px-6 lg:px-8 py-2"
    >
      <div class="flex items-center flex-1 gap-x-4 self-stretch">
        <div class="flex-shrink-0">
          <ExclamationTriangleIcon
            class="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <p class="text-xs text-yellow-700">
          <span class="font-semibold mr-1">{{ prefix }}</span
          ><span class="mr-1"
            >Please add payment details to continue using the app or reach out
            to us for a trial extension.</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
import { storeToRefs } from "pinia";
import { daysBetween } from "~/shared/utils";
import { Workspace } from "~/types/Workspace";

const appUserStore = useAppUserStore();
const { appUserMe } = storeToRefs(appUserStore);
const { getActiveWorkspace } = useWorkspace();
const workspace = computed(() => getActiveWorkspace(appUserMe.value));
const expiresSoon = computed(() => {
  if (workspace.value == null) {
    return false;
  }
  const thresholdDays = 7;
  return (
    workspace.value.subscriptionEndDate &&
    expiresInDays(workspace.value) < thresholdDays &&
    !workspace.value.addedPaymentDetails
  );
});
const expiresInDays = (workspace: Workspace) => {
  if (workspace.subscriptionEndDate == null) {
    return 0;
  }
  return daysBetween(new Date(), workspace.subscriptionEndDate, true);
};
const showNavbar = computed(() => expiresSoon.value && workspace.value);
const prefix = computed(() => {
  if (workspace.value == null) {
    return "";
  }
  const expire = expiresInDays(workspace.value);
  if (expire === 1) {
    return "Your trial expires in 1 day!";
  }
  return `Your trial expires in ${expire} days!`;
});
</script>
