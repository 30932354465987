<template>
  <Modal :open="open" title="✋ Usage exceeded" @close="onClose">
    <template #default>
      <div class="mt-2">
        <p class="text-sm text-gray-500">
          You exceeded your monthly usage limit. DatAds doesn't pull any more
          data until the end of the month. Please upgrade your plan or remove
          accounts / clients to continue using the app.
        </p>
      </div></template
    >
    <template #actions>
      <nuxt-link
        to="/payment/select-plan"
        class="ml-2 inline-flex items-center justify-center disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500 disabled:ring-gray-200 px-3 py-2 text-sm rounded-md bg-primary-400 font-semibold text-white shadow-sm hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400"
        >Upgrade plan</nuxt-link
      >
      <nuxt-link
        to="/settings/clients"
        class="ml-2 inline-flex items-center justify-center disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500 disabled:ring-gray-200 px-3 py-2 text-sm rounded-md bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >Remove client</nuxt-link
      >
    </template>
  </Modal>
</template>

<script lang="ts" setup>
defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const router = useRouter();
const route = useRoute();

const onClose = () => {
  router.push({
    path: route.path,
    query: {
      ...route.query,
      mode: undefined,
    },
  });
};
</script>
